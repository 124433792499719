/* eslint-disable */
import { loginRequest, msalConfig} from "../auth/authConfig.js";
//import { PublicClientApplication, EventType, InteractionStatus } from "@azure/msal-browser";

let msal = await import("@azure/msal-browser");

const msalInstance = new msal.PublicClientApplication(msalConfig);

msalInstance.initialize().then(async () => {
    let response = await msalInstance.handleRedirectPromise();
    if (response === null) {
        msalInstance.loginRedirect(loginRequest);
    } else {
        let accounts = msalInstance.getAllAccounts();
        msalInstance.setActiveAccount(accounts[0]);
        window.close();
    }
});
